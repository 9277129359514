// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Users/rajiteh/dev/store.papadamn.com/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-components-product-listing-index-js": () => import("/Users/rajiteh/dev/store.papadamn.com/src/components/ProductListing/index.js" /* webpackChunkName: "component---src-components-product-listing-index-js" */),
  "component---src-components-faq-faq-js": () => import("/Users/rajiteh/dev/store.papadamn.com/src/components/Faq/Faq.js" /* webpackChunkName: "component---src-components-faq-faq-js" */),
  "component---src-templates-product-page-template-js": () => import("/Users/rajiteh/dev/store.papadamn.com/src/templates/ProductPageTemplate.js" /* webpackChunkName: "component---src-templates-product-page-template-js" */),
  "component---src-pages-404-js": () => import("/Users/rajiteh/dev/store.papadamn.com/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-callback-js": () => import("/Users/rajiteh/dev/store.papadamn.com/src/pages/callback.js" /* webpackChunkName: "component---src-pages-callback-js" */),
  "component---src-pages-login-js": () => import("/Users/rajiteh/dev/store.papadamn.com/src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-product-details-js": () => import("/Users/rajiteh/dev/store.papadamn.com/src/pages/product-details.js" /* webpackChunkName: "component---src-pages-product-details-js" */)
}

