import React from 'react';
import styled, { keyframes } from 'react-emotion';

import UserContext from '../../context/UserContext';
import ContentForNotLoggedIn from './ContentForNotLoggedIn';
import ContentForLoggedIn from './ContentForLoggedIn';

import { breakpoints, colors, spacing, dimensions } from '../../utils/styles';

const ContentContainerRoot = styled(`div`)`
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  padding: ${spacing.lg}px;
  padding-bottom: calc(
    ${spacing.lg}px + ${dimensions.contributorAreaBarHeight}
  );

  @media (min-width: ${breakpoints.desktop}px) {
    padding: ${spacing.xl}px;
    padding-bottom: calc(
      ${spacing.xl}px + ${dimensions.contributorAreaBarHeight}
    );

    ::-webkit-scrollbar {
      height: 6px;
      width: 6px;
    }
    ::-webkit-scrollbar-thumb {
      background: ${colors.brandDarker};
    }
    ::-webkit-scrollbar-thumb:hover {
      background: ${colors.lilac};
    }
    ::-webkit-scrollbar-track {
      background: ${colors.brand};
    }
  }
`;
const entry = keyframes`
   from {
     opacity: 0;
     transform: scale(0.5);
   }
   to {
     opacity: 1;
     transform:  scale(1);
   }
 `;


class ContentContainer extends React.Component {
  render () {
    return (
      <ContentContainerRoot>
        <UserContext.Consumer>
          {({
            contributor,
            error,
            handleLogout,
            loading,
            profile,
            profile: { nickname }
          }) =>
            nickname || loading ? (
              <ContentForLoggedIn
                contributor={contributor}
                error={error}
                handleLogout={handleLogout}
                loading={loading}
                profile={profile}
              />
            ) : (
              <>
                <ContentForNotLoggedIn isDesktopViewport={this.props.isDesktopViewport} closeBarFn={this.props.closeBarFn}/>
              
              </>
            )
          }
        </UserContext.Consumer>
      </ContentContainerRoot>
    );
  }
}

export default ContentContainer;
