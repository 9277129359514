module.exports = [{
      plugin: require('/Users/rajiteh/dev/store.papadamn.com/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/Users/rajiteh/dev/store.papadamn.com/src/components/Layout/index.js"},
    },{
      plugin: require('/Users/rajiteh/dev/store.papadamn.com/node_modules/gatsby-plugin-emotion/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/rajiteh/dev/store.papadamn.com/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/rajiteh/dev/store.papadamn.com/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-49466010-4","respectDNT":true},
    },{
      plugin: require('/Users/rajiteh/dev/store.papadamn.com/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
