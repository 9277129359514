import React from 'react';
import styled, {keyframes} from 'react-emotion';

import { colors, fonts } from '../../utils/styles';

const nameAnimation = keyframes`
  0%{background-position:0% 50%}
  50%{background-position:100% 25%}
  100%{background-position:0% 50%}
`;

const Name = styled(`h6`)`
  color: ${colors.brandYellow};
  font-family: ${fonts.logofont};
  font-weight: 100;
  font-size: 2rem;
  line-height: 1.2;
  margin: 0;
  text-decoration: none;
  padding-top: 10px;

  &:hover {
    background-image: repeating-linear-gradient(45deg, #FBF962, #0B6E2E, #B84075, #D3702C, #FBF962, #B84075, #2E4450);
    animation: ${nameAnimation} 8s ease infinite;
    color: transparent;
    background-size: 800% 800%;
    -webkit-background-clip: text;
    text-fill-color: transparent;
    background-clip: text;
  }
`;





export default () => (
  <div>
    <Name >PAPADAMN</Name>
  </div>
);
