import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'react-emotion';
import { Link } from 'gatsby';
import Logo from './Logo';

import { breakpoints, colors, fonts, dimensions, spacing } from '../../utils/styles';

const HeaderRoot = styled('header')`
  align-items: center;
  background-color: ${colors.darkest};
  border-bottom: 1px solid ${colors.darkest};
  box-sizing: border-box;
  display: ${props => (props.isCovered ? 'none' : 'flex')};
  height: ${dimensions.headerHeight};
  justify-content: space-between;
  left: 0;
  padding-left: ${spacing.lg}px;
  padding-right: ${dimensions.headerHeight};
  position: sticky;
  right: 0;
  top: 0;
  z-index: 1000;
  box-shadow: 0 2px 3px 1px rgba(0,0,0,0.1);

  @media (min-width: ${breakpoints.desktop}px) {
    padding-left: ${spacing.xl}px;
    &.covered {
      display: none;
    }
  }
`;

const HomeLink = styled(Link)`
  display: block;
  flex-shrink: 1;
  line-height: 1;
  margin-right: auto;
  text-decoration: none;
`;

const HeaderMenu = styled('ul')`
  text-decoration: none;
  font-family: ${fonts.heading};
  li {
    display:inline;
    margin: 0 24px;
  }

  @media (max-width: ${breakpoints.tablet}px) {
    display: none;
  }
`;

const HeaderMenuItem = styled('li')``;

const navBarLinkStyles = css`
  text-decoration: none;
  font-weight: 700;
  &:visited {
    color: inherit;
  }
  &:hover {
    color: ${colors.brandYellow}
  }
`

const activeNavBarElement = css`
  color: ${colors.brandYellow} !important;
`;

const inactiveNavBarElement = css`
  color: ${colors.text};
`


class Header extends Component {
  state = {
    className: '',
    menuItems: [
      {
        title: 'Merch',
        primary: true,
        internal: true,
        link: '/'
      },
      {
        title: 'Questions?',
        internal: true,
        link: '/faq?fromProduct'
      },
      {
        title: 'Papadamn Comics',
        link: 'https://papadamn.com/'
      },
    ]
  };

  componentDidUpdate(prevProps) {
    if (this.props.isDesktopViewport) {
      const imageBrowserStatusChanged =
        this.props.productImagesBrowserStatus !==
        prevProps.productImagesBrowserStatus;

      if (imageBrowserStatusChanged) {
        if (this.props.productImagesBrowserStatus === 'open') {
          setTimeout(() => {
            this.setState({
              className: 'covered'
            });
          }, 500);
        } else {
          this.setState({
            className: ''
          });
        }
      }
    }
  }

  render() {
    const { className, menuItems } = this.state;

    return (
      <HeaderRoot className={className}>
        <HomeLink to="/" aria-label="Home page">
          <Logo />
        </HomeLink>
        <HeaderMenu>
          {menuItems.map((item, i) => 
            <HeaderMenuItem key={i}>
              {(item.internal ? 
                <Link className={navBarLinkStyles} to={item.link} css={item.primary ? activeNavBarElement : inactiveNavBarElement} aria-label={item.title}>
                  {item.title}
                </Link>
                :
                <a className={navBarLinkStyles} href={item.link} css={item.primary ? activeNavBarElement : inactiveNavBarElement} target={item.internal ? "_self" : "_blank"} aria-label={item.title}>
                  {item.title}
                </a>
              )}
            </HeaderMenuItem>)}
        </HeaderMenu>
      </HeaderRoot>
    );
  }
}

Header.propTypes = {
  productImagesBrowserStatus: PropTypes.string.isRequired,
  isDesktopViewport: PropTypes.bool
};

export default Header;
