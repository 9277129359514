import React from 'react';
import styled from 'react-emotion';
import { GoMarkGithub } from 'react-icons/go';

import { login } from '../../utils/auth';
import { Button as BaseButton } from '../shared/Buttons';
import { Heading, SectionHeading, Text } from './AreaTypography';
import { breakpoints, spacing, animations } from '../../utils/styles';
import papadamnLogo from '../../assets/papadamn-logo.png';


const ContentForGuestRoot = styled(`div`)`
  animation: ${animations.simpleEntry};
  position: relative;
`;

const FirstHeading = styled(Heading)`
  padding-right: ${spacing.lg}px;
`;

const Button = styled(BaseButton)`
  margin: ${spacing.lg}px 0 ${spacing.xl}px 0;
`;

const LogoImage = styled(`img`)`
  display: none;
  @media (min-width: ${breakpoints.desktop}px) {
    display: block;
    max-width: 95%;
  }
`

class ContentForGuest extends React.Component {
  render () {
    return (
      <ContentForGuestRoot>
        <LogoImage src={papadamnLogo}/>

        <SectionHeading>Thank you for supporting us!</SectionHeading>
        <Text>
          Creating comics is difficult and time consuming work. Your support counts a lot and it will let us to dedicate more time and love to the characters we bring to life. Thank you!{' '}❤️
        </Text>
        <br/>

        <SectionHeading>We're still growing!</SectionHeading>
        <Text>
          We're still building our platform. If you come across any problems or have any questions, concerns, or compliments please tell us!
        </Text>
        <Button
          inverse
          to="/faq?fromProduct"
          onClick={!this.props.isDesktopViewport ? this.props.closeBarFn : null}
        >
          Questions?
        </Button>
      </ContentForGuestRoot>
    )
  }
};

export default ContentForGuest;
